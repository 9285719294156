import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './ButtonStandard.module.scss'

import ButtonBase from '../ButtonBase'
import Spinner from '../Spinner'

const ButtonStandard = ({
  children,
  className,
  disabled,
  loading,
  small,
  light,
  color,
  accent,
  ...other
}) => {
  return (
    <ButtonBase
      className={classNames(
        styles.ButtonStandard,
        light && styles.light,
        disabled && styles.disabled,
        loading && styles.loading,
        small && styles.small,
        accent && styles.accent,
        className
      )}
      disabled={disabled}
      {...other}
    >
      <span
        className={styles.ButtonStandardContent}
        style={{ color: color ? `#${color}` : null }}
      >
        {children}
      </span>
      {loading && (
        <span className={styles.ButtonStandardSpinner}>
          <Spinner revealDelay={200} />
        </span>
      )}
    </ButtonBase>
  )
}

ButtonStandard.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  loading: bool,
  small: bool,
  light: bool,
  color: string,
  accent: bool
}

export default ButtonStandard
